var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_c('h2',[_vm._v("Pairwise cosine similarities")])]),_c('v-card-text',[_c('vue-markdown',{attrs:{"source":_vm.docs.pairwiseDoc}}),_c('h3',[_vm._v("Step 1: Search for entities to include in the comparison")]),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{staticClass:"px-4",attrs:{"items":_vm.dictionaryOptions,"label":"Select dictionary"},model:{value:(_vm.dictionary),callback:function ($$v) {_vm.dictionary=$$v},expression:"dictionary"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{staticClass:"px-4",attrs:{"disabled":_vm.entSelectDisabled,"label":_vm.entSelectLabel,"search-input":_vm.entityAcQuery,"loading":_vm.isEntitySearchLoading,"items":_vm.entityOptions,"item-text":"ent_term","return-object":true},on:{"update:searchInput":function($event){_vm.entityAcQuery=$event},"update:search-input":function($event){_vm.entityAcQuery=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_c('span',[_vm._v("Type to search entity by its label")])])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"input-value":selected}},'v-chip',attr,false),on),[_c('span',[_c('i',[_vm._v(_vm._s(item.dictionary))])]),_c('span',{staticClass:"pl-4"},[_vm._v(_vm._s(item.ent_id))]),_c('span',{staticClass:"pl-4"},[_c('b',[_vm._v(_vm._s(item.ent_term))])])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('entity-candidate',{attrs:{"ent-id":item.ent_id,"ent-term":item.ent_term,"dictionary":item.dictionary}})]}}]),model:{value:(_vm.entityToSearch),callback:function ($$v) {_vm.entityToSearch=$$v},expression:"entityToSearch"}})],1),_c('v-col',[_c('v-btn',{attrs:{"disabled":_vm.includeBtnDisabled},on:{"click":_vm.addEntToList}},[_vm._v("Add to list")])],1)],1),(_vm.pairwiseEntities.length > 0)?_c('div',[_c('v-subheader',[_vm._v("Included entities")]),_c('v-item-group',{attrs:{"multiple":""}},_vm._l((_vm.pairwiseEntities),function(item,idx){return _c('v-item',{key:idx,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-chip',{attrs:{"active-class":"purple--text","input-value":active}},[_c('entity-span',{attrs:{"ent-id":item.ent_id,"ent-term":item.ent_term,"dictionary":item.dictionary}})],1)]}}],null,true)})}),1)],1):_vm._e(),_c('v-divider'),_c('h3',[_vm._v("Step 2: Configure parameters")]),_c('v-subheader',[_vm._v("Other parameters")]),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{staticClass:"px-4",attrs:{"label":"Select embedding model","items":_vm.embeddingTypeOptions},model:{value:(_vm.embeddingType),callback:function ($$v) {_vm.embeddingType=$$v},expression:"embeddingType"}})],1)],1),_c('div',{staticClass:"d-flex justify-center mb-6"},[_c('v-btn',{attrs:{"disabled":_vm.submitBtnDisabled,"color":"primary","large":""},on:{"click":_vm.submit}},[_vm._v("Submit")])],1),_c('v-divider'),(_vm.pairwiseResults.length > 0)?_c('div',[_c('h3',[_vm._v("Results")]),_c('pairwise-table',{attrs:{"items":_vm.pairwiseResults}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }